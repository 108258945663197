/* Décommenter la ligne si dessous pour activer UNISON */
// var allBreakpoints = Unison.fetch.all();


/*
      ##    #######   ##     ##  ########   ########   ##    ##   
      ##   ##     ##  ##     ##  ##         ##     ##   ##  ##    
      ##   ##     ##  ##     ##  ##         ##     ##    ####     
      ##   ##     ##  ##     ##  ######     ########      ##      
##    ##   ##  ## ##  ##     ##  ##         ##   ##       ##      
##    ##   ##    ##   ##     ##  ##         ##    ##      ##      
 ######     ##### ##   #######   ########   ##     ##     ##      
*/

function init() {
   window.addEventListener('scroll', function(e){
       var distanceY = window.pageYOffset || document.documentElement.scrollTop,
           shrinkOn = 36,
           header = document.querySelector("header");
       if (distanceY > shrinkOn) {
           classie.add(header,"smaller");
       } else {
           if (classie.has(header,"smaller")) {
               classie.remove(header,"smaller");
           }
       }
   });
}

$(document).ready(function() {

	var nav = responsiveNav(".nav-collapse", { // Selector
		label: $("li a.current", this).html()+" <span class='icon-chevron-small-right'></span> "
	});

	FastClick.attach(document.body);

	/**
	 * Test si les Media Queries sont supportées
	 * Ajoute la classe 'mq' au tag HTML si c'est le cas
	 */
	if ( Modernizr.mq('only all') ) {
		jQuery('html').addClass('mq');
	} else {
		jQuery('html').addClass('no-mq');
	}

	/**
	 * Browser detect : masquage de la bannière
	 */
	$('.browserdetect__close').click(function(e) {
		e.preventDefault();
		$('.browserdetect').addClass('browserdetect--hidden');
	});

	/**
	 * MatchHeight [GRID]
	 */
	$(".harmonize").matchHeight();


	if ($('#cbp-so-scroller').length > 0) {
		new cbpScroller( document.getElementById( 'cbp-so-scroller' ) );
	}
	if ($('#cbp-so-scroller-2').length > 0) {
		new cbpScroller( document.getElementById( 'cbp-so-scroller-2' ) );
	}
	if ($('#cbp-so-scroller-presentation').length > 0) {
		new cbpScroller( document.getElementById( 'cbp-so-scroller-presentation' ) );
	}
	if ($('#cbp-so-scroller-presentation-2').length > 0) {
		new cbpScroller( document.getElementById( 'cbp-so-scroller-presentation-2' ) );
	}
	if ($('#cbp-so-scroller-produit').length > 0) {
		new cbpScroller( document.getElementById( 'cbp-so-scroller-produit' ) );
	}
	if ($('#cbp-so-scroller-produit-2').length > 0) {
		new cbpScroller( document.getElementById( 'cbp-so-scroller-produit-2' ) );
	}
	if ($('#cbp-so-scroller-produit-3').length > 0) {
		new cbpScroller( document.getElementById( 'cbp-so-scroller-produit-3' ) );
	}
	if ($('#cbp-so-scroller-partenaire').length > 0) {
		new cbpScroller( document.getElementById( 'cbp-so-scroller-partenaire' ) );
	}
	if ($('#cbp-so-scroller-partenaire-2').length > 0) {
		new cbpScroller( document.getElementById( 'cbp-so-scroller-partenaire-2' ) );
	}
	if ($('#cbp-so-scroller-presse').length > 0) {
		new cbpScroller( document.getElementById( 'cbp-so-scroller-presse' ) );
	}
	if ($('#cbp-so-scroller-actu').length > 0) {
		new cbpScroller( document.getElementById( 'cbp-so-scroller-actu' ) );
	}
	if ($('#cbp-so-scroller-actudetail').length > 0) {
		new cbpScroller( document.getElementById( 'cbp-so-scroller-actudetail' ) );
	}
	if ($('#cbp-so-scroller-service').length > 0) {
		new cbpScroller( document.getElementById( 'cbp-so-scroller-service' ) );
	}



	/**
	 * MENU MOBILE
	 */
	$('#toggle').click(function() {
		$(this).toggleClass('active');
		$('#overlay').toggleClass('open');
	});

	/**
	 * STICKY NAVIGATION
	 */
	if ($('#sticky').length > 0) {
	    window.onload = init();
	}

	/**
	 * LIGHTGALLERY
	 */

	if ($('.lightgallery').length > 0) {
		$(".lightgallery").lightGallery({
			mode: 'lg-slide',
			selector: '.selector',
			counter: false,
			download: false
		});
	}

	if ($('a.zoom').length > 0) {
		$(".editor-text").lightGallery({
			selector: '.zoom',
			counter: false,
			download: false
		});
	}

	/**
	 * MAP
	 */
	if( $("#map").length > 0 ){
		loadMap(); 
	}

	/* PHOTOS/VIDEOS [Isotope] */
	// var $grid = $('#gallery-list').isotope({itemSelector : 'li'});
	
	/// VIDÉOS ////
	if( $(".video-list").length > 0 ){
		$(".video-list li").fitVids();
	}
	
	/// PHOTOS ///
	if( $(".gallery-list").length > 0 ){

		var loadedImages = 0, // Counter for loaded images
			$progressOverlay = $('.progress-bar-overlay'),
			$progressBar     = $('.progress-bar'),
			$progressText    = $('.progress-bar-text'),
			container        = '.gallery-list',
			$container       = $(container),
			tileCount        = $container.find('li').length,
			wookmark;

		// Initialize Wookmark
		wookmark = new Wookmark(container, {
			itemWidth: $container.data('itemwidth'), // Optional, the width of a grid item
			flexibleWidth: $container.data('flexwidth'),
			align: 'left',
		});
		var imgLoad = imagesLoaded( container );
		imgLoad.on("done", function (instance) {
			$progressOverlay.addClass('fade');
		}).on("progress", function (instance, image) {
			// Update progress bar after each image has loaded and remove loading state
			$(image.img).closest('li').removeClass('wookmark-inactive');
			percentage = (++loadedImages / tileCount * 100);
			$progressBar.css('width', Math.ceil(percentage) + '%');
			$progressText.html(Math.ceil(percentage) + '%');
			wookmark.updateOptions();
		});

		$progressOverlay.addClass('fade');

		/**
		* When a filter is clicked, toggle it's active state and refresh.
		*/
		var onClickFilter = function(e) {
			// Setup filter buttons when jQuery is available
			var $filters = $('.gallery-filters li');

			var $item = $(e.currentTarget),
	            itemActive = $item.hasClass('filter-item-current');

	        if (!itemActive) {
	          $filters.removeClass('filter-item-current');
	          itemActive = true;
	        } else {
	          itemActive = false;
	        }
	        $item.toggleClass('filter-item-current');

			// Filter by the currently selected filter
	        wookmark.filter(itemActive ? [$item.data('filter')] : []);
		};

		$('.gallery-filters').on('click.wookmark-filter', 'li', onClickFilter);
	}

	/*
	 ######     #######   ##    ##   ########      ###      ######    ########   
	##    ##   ##     ##  ###   ##      ##        ## ##    ##    ##      ##      
	##         ##     ##  ####  ##      ##       ##   ##   ##            ##      
	##         ##     ##  ## ## ##      ##      ##     ##  ##            ##      
	##         ##     ##  ##  ####      ##      #########  ##            ##      
	##    ##   ##     ##  ##   ###      ##      ##     ##  ##    ##      ##      
	 ######     #######   ##    ##      ##      ##     ##   ######       ##      
	*/

	/**
	 * Style input filled
	 */
	if (!String.prototype.trim) {
		(function() {
			// Make sure we trim BOM and NBSP
			var rtrim = /^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g;
			String.prototype.trim = function() {
				return this.replace(rtrim, '');
			};
		})();
	}

	[].slice.call( document.querySelectorAll( 'input.input__field' ) ).forEach( function( inputEl ) {
		// in case the input is already filled..
		if( inputEl.value.trim() !== '' ) {
			classie.add( inputEl.parentNode, 'input--filled' );
		}

		// events:
		inputEl.addEventListener( 'focus', onInputFocus );
		inputEl.addEventListener( 'blur', onInputBlur );
	} );

	function onInputFocus( ev ) {
		classie.add( ev.target.parentNode, 'input--filled' );
	}

	function onInputBlur( ev ) {
		if( ev.target.value.trim() === '' ) {
			classie.remove( ev.target.parentNode, 'input--filled' );
		}
	}

	$("form").validationEngine({ 
		scroll: false,
		showPrompts: false,
		onFieldFailure: function(_input){
			_input.parent('li').addClass('error');
		},
		onFieldSuccess: function(_input){
			_input.parent('li').removeClass('error');
		}
	});

	$("#fcontact").submit(function(){
		if($("#fcontact").validationEngine('validate')){
			var url  = $(this).attr('action');
			var data = $(this).serialize() + '&act=envoi';
			var div  = $(this).empty().html('<div class="form-return">envoi en cours...</div>');

			$.ajax({
				type: "POST",
				data: data,
				url: url,
				success: function(retour) {
					div.empty().append(retour);
				}
			});
		}
		return false;
	});


	/* PRESSE */

	$(".presse-download").click(function(e) {
		e.preventDefault();
		$(this).next().slideToggle();
	});

	$("#DossierPresse").validationEngine();	
	$("#DossierPresse").submit(function(){
		if($("#DossierPresse").validationEngine('validate')){
			var data = $(this).serialize() + '&act=envoi';
			var url  = $(this).attr('action');
			$.ajax({
				type: "POST",
				data : data,
				url: url,
				success: function(retour) {
					window.open('/fichier/presse-2015-fr.pdf');
				}
			});
		}
		return false;
	});

	$('.slick-slider-home').slick({
		dots: true,
		dotsClass: 'slick-slider-home-dots',
		infinite: true,
		speed: 500,
		autoplay: true,
		autoplaySpeed: 1800,
		prevArrow: $('.slick-slider-home-arrow--prev'),
		nextArrow: $('.slick-slider-home-arrow--next')
	});

	// ICI
	$('.fade').slick({
		dots: true,
		infinite: true,
		speed: 500,
		fade: true,
		cssEase: 'linear',
		autoplay: true,
		autoplaySpeed: 1800,
	  });

	  $('.center').slick({
		centerMode: true,
		centerPadding: '60px',
		slidesToShow: 3,
		responsive: [
		  {
			breakpoint: 768,
			settings: {
			  arrows: false,
			  centerMode: true,
			  centerPadding: '40px',
			  slidesToShow: 3
			}
		  },
		  {
			breakpoint: 480,
			settings: {
			  arrows: false,
			  centerMode: true,
			  centerPadding: '40px',
			  slidesToShow: 1
			}
		  }
		]
	  });
		  
});
